import React, { useState } from "react";

const images = [
  "/images/1.jpg",
  "/images/2.jpg",
  "/images/3.jpg",
  "/images/4.jpg",
  "/images/5.jpg",
  "/images/6.jpg",
  // Add more image paths here
];

// BST Node
class TreeNode {
  constructor(value, image) {
    this.value = value;
    this.image = image;
    this.left = null;
    this.right = null;
  }
}

// Insert into BST
const insertIntoBST = (root, value) => {
  const image = images[Math.floor(Math.random() * images.length)];
  if (!root) return new TreeNode(value, image);
  if (value < root.value) root.left = insertIntoBST(root.left, value);
  else root.right = insertIntoBST(root.right, value);
  return root;
};

// Traversals
const traverseInOrder = (root, result = []) => {
  if (!root) return result;
  traverseInOrder(root.left, result);
  result.push(root);
  traverseInOrder(root.right, result);
  return result;
};

const traversePreOrder = (root, result = []) => {
  if (!root) return result;
  result.push(root);
  traversePreOrder(root.left, result);
  traversePreOrder(root.right, result);
  return result;
};

const traversePostOrder = (root, result = []) => {
  if (!root) return result;
  traversePostOrder(root.left, result);
  traversePostOrder(root.right, result);
  result.push(root);
  return result;
};

// Tree visualization component
const TreeNodeComponent = ({ node, x, y, levelWidth, highlightedNodes }) => {
  if (!node) return null;

  const leftX = x - levelWidth;
  const rightX = x + levelWidth;

  const isHighlighted = highlightedNodes?.includes(node);

  return (
    <div style={{ position: "relative" }}>
      {/* Current node */}
      <div
        className="node"
        style={{
          position: "absolute",
          top: `${y}px`,
          left: `${x}px`,
          transform: "translate(-50%, -50%)",
          backgroundImage: `url(${node.image})`,
          backgroundSize: "cover",
          backgroundColor: isHighlighted ? "gold" : "#333",
          color: "#fff",
          border: isHighlighted ? "3px solid #f00" : "none",
          borderRadius: "50%",
          width: "60px",
          height: "60px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontWeight: "bold",
          boxShadow: isHighlighted
            ? "0 4px 8px rgba(255, 0, 0, 0.6)" // More prominent shadow for highlighted state
            : "0 4px 8px rgba(0, 0, 0, 0.3)", // Subtle shadow for normal state
        }}
      >
        {node.value}
      </div>

      {/* Left child */}
      {node.left && (
        <>
          {/* Line connecting to left child */}
          <div
            style={{
              position: "absolute",
              top: `${y + 30}px`,
              left: `${x}px`,
              width: `${levelWidth}px`,
              height: "2px",
              backgroundColor: "gray",
              transformOrigin: "top left",
              transform: `rotate(${Math.atan2(100, -levelWidth) * (180 / Math.PI)}deg)`, // Adjusted for left angle
            }}
          ></div>

          {/* Recursively render left child */}
          <TreeNodeComponent
            node={node.left}
            x={leftX}
            y={y + 100}
            levelWidth={levelWidth / 2}
            highlightedNodes={highlightedNodes}
          />
        </>
      )}

      {/* Right child */}
      {node.right && (
        <>
          {/* Line connecting to right child */}
          <div
            style={{
              position: "absolute",
              top: `${y + 30}px`,
              left: `${x}px`,
              width: `${levelWidth}px`,
              height: "2px",
              backgroundColor: "gray",
              transformOrigin: "top left",
              transform: `rotate(${Math.atan2(60, levelWidth) * (180 / Math.PI)}deg)`,
            }}
          ></div>

          {/* Recursively render right child */}
          <TreeNodeComponent
            node={node.right}
            x={rightX}
            y={y + 100}
            levelWidth={levelWidth / 2}
            highlightedNodes={highlightedNodes}
          />
        </>
      )}
    </div>
  );
};

const App = () => {
  const [root, setRoot] = useState(null);
  const [input, setInput] = useState("");
  const [highlightedNodes, setHighlightedNodes] = useState([]);

  const handleInsert = () => {
    const value = parseInt(input);
    if (!isNaN(value)) {
      const newRoot = insertIntoBST(root, value);
      setRoot(newRoot);
      setInput("");
      setHighlightedNodes([]); // Clear highlights after insertion
    }
  };

  const handleTraversal = (type) => {
    if (!root) return;
    let result = [];
    if (type === "in-order") result = traverseInOrder(root);
    else if (type === "pre-order") result = traversePreOrder(root);
    else if (type === "post-order") result = traversePostOrder(root);
  
    // Clear highlights and animate the traversal
    setHighlightedNodes([]);
    result.forEach((node, index) => {
      setTimeout(() => {
        setHighlightedNodes((prev) => [...prev, node]); // Add node to highlighted list
      }, index * 500); // 500ms delay for each step
    });
  };

  return (
    <div className="app">
      <h1>Alistair Moffat Fan Club BST Visualisation</h1>
      <div className="controls">
        <input
          type="number"
          value={input}
          onChange={(e) => setInput(e.target.value)}
          placeholder="Enter a number"
        />
        <button onClick={handleInsert}>Insert</button>
        <button onClick={() => handleTraversal("in-order")}>In-Order</button>
        <button onClick={() => handleTraversal("pre-order")}>Pre-Order</button>
        <button onClick={() => handleTraversal("post-order")}>Post-Order</button>
      </div>
      <div
        className="tree-container"
        style={{
          position: "relative",
          width: "100%",
          height: "600px",
          overflowX: "auto",
          overflowY: "hidden",
          border: "1px solid #ccc",
        }}
      >
        {root && (
          <TreeNodeComponent
            node={root}
            x={window.innerWidth / 2}
            y={50}
            levelWidth={300}
            highlightedNodes={highlightedNodes}
          />
        )}
      </div>
    </div>
  );
};

export default App;
